

// import React, { useRef, useState } from 'react';
// import emailjs from '@emailjs/browser';
// import { Container, Typography, TextField, Button, Grid, Box, Alert } from '@mui/material';
// import Confetti from 'react-confetti';

// const ContactRequest = () => {
//   const form = useRef();
//   const [formSubmitted, setFormSubmitted] = useState(false);
//   const [errorFields, setErrorFields] = useState([]);
//   const [errorMessage, setErrorMessage] = useState('');

//   // Function to validate email format
//   const validateEmail = (email) => {
//     const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return regex.test(email);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const formData = new FormData(form.current);
//     const errors = [];

//     // Required fields
//     ['firstName', 'lastName', 'email', 'comment'].forEach((name) => {
//       if (!formData.get(name)) {
//         errors.push(name);
//       }
//     });

//     // Validate email field separately for correct format
//     if (!validateEmail(formData.get('email'))) {
//       errors.push('email');
//       setErrorMessage('Please enter a valid email address.');
//     } else {
//       setErrorMessage('');
//     }

//     if (errors.length > 0) {
//       setErrorFields(errors);
//       if (!errorMessage) {
//         setErrorMessage('Missing required fields.');
//       }
//       return;
//     }

//     emailjs.sendForm(
//       process.env.REACT_APP_EMAILJS_SERVICE_ID,
//       process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
//       form.current,
//       process.env.REACT_APP_EMAILJS_USER_ID
//     ).then(
//       (result) => {
//         console.log('SUCCESS!', result.status, result.text);
//         setFormSubmitted(true);
//       },
//       (error) => {
//         console.log('FAILED...', error.text);
//         setErrorMessage('Failed to send your contact request. Please try again.');
//       }
//     );
//   };

//   if (formSubmitted) {
//     return (
//       <Container maxWidth="sm" style={{ marginTop: '100px', textAlign: 'center' }}>
//         <Confetti width={window.width} height={window.height} />
//         <Typography variant="h2" gutterBottom>
//           Success!
//         </Typography>
//         <Typography variant="h5">
//           Your contact request was received. Someone will reach out to you.
//         </Typography>
//       </Container>
//     );
//   }

//   return (
//     <Container maxWidth="md">
//       <Typography variant="h4" gutterBottom>Contact Request</Typography>
//       {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
//       <Box component="form" ref={form} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
//         <Grid container spacing={2}>
//           <TextFieldWithError
//             name="firstName"
//             label="First Name"
//             errorFields={errorFields}
//             required
//           />
//           <TextFieldWithError
//             name="lastName"
//             label="Last Name"
//             errorFields={errorFields}
//             required
//           />
//           <TextFieldWithError
//             name="company"
//             label="Company"
//             errorFields={errorFields}
//           />
//           <TextFieldWithError
//             name="email"
//             label="Email Address"
//             type="email"
//             errorFields={errorFields}
//             required
//           />
//           <TextFieldWithError
//             name="phone"
//             label="Phone (Optional)"
//             errorFields={errorFields}
//           />
//           <TextFieldWithError
//             name="comment"
//             label="Comment"
//             multiline
//             rows={4}
//             errorFields={errorFields}
//             required
//           />
//           <Grid item xs={12}>
//             <Button type="submit" variant="contained" fullWidth sx={{ mt: 3, mb: 2 }}>
//               Submit
//             </Button>
//           </Grid>
//         </Grid>
//       </Box>
//     </Container>
//   );
// };

// const TextFieldWithError = ({ name, label, errorFields, ...props }) => {
//   const isError = errorFields.includes(name);
//   return (
//     <Grid item xs={12}>
//       <TextField
//         {...props}
//         name={name}
//         label={label}
//         variant="outlined"
//         fullWidth
//         error={isError}
//         helperText={isError ? (name === 'email' ? "Invalid email address." : "This field is required.") : ""}
//       />
//     </Grid>
//   );
// };

// export default ContactRequest;






// import React, { useRef, useState } from 'react';
// import emailjs from '@emailjs/browser';
// import { Container, Typography, TextField, Button, Grid, Box, Alert } from '@mui/material';
// import Confetti from 'react-confetti';

// const ContactRequest = () => {
//   const form = useRef();
//   const [formSubmitted, setFormSubmitted] = useState(false);
//   const [errorFields, setErrorFields] = useState([]);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [btnText, setBtnText] = useState('Send Email');

//   // Function to validate email format
//   const validateEmail = (email) => {
//     const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return regex.test(email);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     setBtnText('Sending...');
//     const formData = new FormData(form.current);
//     const errors = [];

//     // Required fields
//     ['firstName', 'lastName', 'email', 'comment'].forEach((name) => {
//       if (!formData.get(name)) {
//         errors.push(name);
//       }
//     });

//     // Validate email field separately for correct format
//     if (!validateEmail(formData.get('email'))) {
//       errors.push('email');
//       setErrorMessage('Please enter a valid email address.');
//     } else {
//       setErrorMessage('');
//     }

//     if (errors.length > 0) {
//       setErrorFields(errors);
//       if (!errorMessage) {
//         setErrorMessage('Missing required fields.');
//       }
//       setBtnText('Send Email');
//       return;
//     }

//     emailjs.sendForm(
//       process.env.REACT_APP_EMAILJS_SERVICE_ID,
//       process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
//       form.current,
//       process.env.REACT_APP_EMAILJS_USER_ID
//     ).then(
//       (result) => {
//         console.log('SUCCESS!', result.status, result.text);
//         setFormSubmitted(true);
//       },
//       (error) => {
//         console.log('FAILED...', error.text);
//         setErrorMessage('Failed to send your contact request. Please try again.');
//         setBtnText('Send Email');
//       }
//     ).catch((error) => {
//       console.error('EmailJS error:', error);
//       setErrorMessage('An unexpected error occurred. Please try again.');
//       setBtnText('Send Email');
//     });
//   };

//   if (formSubmitted) {
//     return (
//       <Container maxWidth="sm" style={{ marginTop: '100px', textAlign: 'center' }}>
//         <Confetti width={window.innerWidth} height={window.innerHeight} />
//         <Typography variant="h2" gutterBottom>
//           Success!
//         </Typography>
//         <Typography variant="h5">
//           Your contact request was received. Someone will reach out to you.
//         </Typography>
//       </Container>
//     );
//   }

//   return (
//     <Container maxWidth="md">
//       <Typography variant="h4" gutterBottom>Contact Request</Typography>
//       {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
//       <Box component="form" ref={form} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
//         <Grid container spacing={2}>
//           <TextFieldWithError
//             name="firstName"
//             label="First Name"
//             errorFields={errorFields}
//             required
//           />
//           <TextFieldWithError
//             name="lastName"
//             label="Last Name"
//             errorFields={errorFields}
//             required
//           />
//           <TextFieldWithError
//             name="company"
//             label="Company"
//             errorFields={errorFields}
//           />
//           <TextFieldWithError
//             name="email"
//             label="Email Address"
//             type="email"
//             errorFields={errorFields}
//             required
//           />
//           <TextFieldWithError
//             name="phone"
//             label="Phone (Optional)"
//             errorFields={errorFields}
//           />
//           <TextFieldWithError
//             name="comment"
//             label="Comment"
//             multiline
//             rows={4}
//             errorFields={errorFields}
//             required
//           />
//           <Grid item xs={12}>
//             <Button type="submit" variant="contained" fullWidth sx={{ mt: 3, mb: 2 }}>
//               {btnText}
//             </Button>
//           </Grid>
//         </Grid>
//       </Box>
//     </Container>
//   );
// };

// const TextFieldWithError = ({ name, label, errorFields, ...props }) => {
//   const isError = errorFields.includes(name);
//   return (
//     <Grid item xs={12}>
//       <TextField
//         {...props}
//         name={name}
//         label={label}
//         variant="outlined"
//         fullWidth
//         error={isError}
//         helperText={isError ? (name === 'email' ? "Invalid email address." : "This field is required.") : ""}
//       />
//     </Grid>
//   );
// };

// export default ContactRequest;






import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Container, Typography, TextField, Button, Grid, Box, Alert } from '@mui/material';
import Confetti from 'react-confetti';

const ContactRequest = () => {
  const form = useRef();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [btnText, setBtnText] = useState('Send Email');

  const sendEmail = (e) => {
    e.preventDefault();
    setBtnText('Sending...');

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      form.current,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    ).then(
      () => {
        console.log('SUCCESS!');
        setFormSubmitted(true);
      },
      (error) => {
        console.log('FAILED...', error.text);
        setErrorMessage('Failed to send your contact request. Please try again.');
        setBtnText('Send Email');
      }
    ).catch((error) => {
      console.error('EmailJS error:', error);
      setErrorMessage('An unexpected error occurred. Please try again.');
      setBtnText('Send Email');
    });
  };

  if (formSubmitted) {
    return (
      <Container maxWidth="sm" style={{ marginTop: '100px', textAlign: 'center' }}>
        <Confetti width={window.innerWidth} height={window.innerHeight} />
        <Typography variant="h2" gutterBottom>
          Success!
        </Typography>
        <Typography variant="h5">
          Your contact request was received. Someone will reach out to you.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>Contact Request</Typography>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Box component="form" ref={form} onSubmit={sendEmail} noValidate sx={{ mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField name="firstName" label="First Name" variant="outlined" fullWidth required />
          </Grid>
          <Grid item xs={12}>
            <TextField name="lastName" label="Last Name" variant="outlined" fullWidth required />
          </Grid>
          <Grid item xs={12}>
            <TextField name="company" label="Company" variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField name="email" label="Email Address" type="email" variant="outlined" fullWidth required />
          </Grid>
          <Grid item xs={12}>
            <TextField name="phone" label="Phone (Optional)" variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField name="comment" label="Comment" multiline rows={4} variant="outlined" fullWidth required />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" fullWidth sx={{ mt: 3, mb: 2 }}>
              {btnText}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ContactRequest;
