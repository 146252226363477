// src/theme.js
//original
// import { createTheme } from '@mui/material/styles';

// // Create a theme instance.
// const theme = createTheme({
//   typography: {
//     fontFamily: "'Poppins', sans-serif",
//   },
//   palette: {
//     primary: {
//       main: '#556cd6',
//     },
//     // ... you can define other colors like error, warning, info, success, and more
//   },
//   // ... you can also customize other theme aspects
// });

// export default theme;

//working but not ideal
// import { createTheme } from '@mui/material/styles';

// const theme = createTheme({
//   palette: {
//     primary: {
//       // Trust & Expertise Blue
//       main: 'rgb(34, 85, 170)',
//     },
//     secondary: {
//       // Growth & Learning Green
//       main: 'rgb(60, 180, 75)',
//     },
//     text: {
//       // Dark enough for contrast but not black
//       primary: 'rgba(0, 0, 0, 0.87)',
//       secondary: 'rgba(0, 0, 0, 0.54)',
//     },
//     background: {
//       // Use a light gray/silver for technological & future feel
//       default: 'rgb(247, 249, 250)', // This is a common choice for a near-white background
//       paper: 'rgb(255, 255, 255)',
//     },
//   },
//   typography: {
//     fontFamily: "'Poppins', sans-serif",
//     button: {
//       textTransform: 'none', // If you prefer button texts to be normally cased
//     },
//     // Define other text styles as needed
//   },
//   // Include additional theme customizations here
// });

// export default theme;

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    // Style overrides for the MUI Card component
    MuiCard: {
      styleOverrides: {
        root: {
          // Add a default margin and padding to all cards
          margin: 'auto',
          padding: '20px',
          borderRadius: '8px', // Optional: rounded corners for all cards
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)', // Optional: light shadow for depth
          // Adjust the maxWidth for different screen sizes if needed
        },
      },
    },
    // Adjustments for the MUI Container component
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '0 16px', // Adjust side padding for all containers
          '@media (min-width:600px)': {
            padding: '0 24px', // Larger padding for bigger screens
          },
          '@media (min-width:960px)': {
            padding: '0 32px', // Even larger padding for larger screens
          },
        },
      },
    },
    // If you have other global component overrides, add them here
  },
  palette: {
    primary: {
      // Trust & Expertise Blue
      main: 'rgb(34, 85, 170)',
    },
    secondary: {
      // Growth & Learning Green
      main: 'rgb(60, 180, 75)',
    },
    text: {
      // Dark enough for contrast but not black
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
    },
    background: {
      // Use a light gray/silver for technological & future feel
      default: 'rgb(247, 249, 250)', // This is a common choice for a near-white background
      paper: 'rgb(255, 255, 255)',
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    button: {
      textTransform: 'none', // If you prefer button texts to be normally cased
    },

  },
  // Include additional theme customizations here
});

export default theme;