// // ScrollContext.js
// import React, { createContext, useContext, useCallback } from 'react';

// const ScrollContext = createContext();

// export const useScroll = () => useContext(ScrollContext);

// export const ScrollProvider = ({ children }) => {
//   const scrollTo = useCallback((id) => {
//     const element = document.getElementById(id);
//     if (element) {
//       element.scrollIntoView({ behavior: 'smooth', block: 'start' });
//     }
//   }, []);

//   return <ScrollContext.Provider value={scrollTo}>{children}</ScrollContext.Provider>;
// };



// ScrollContext.js
import { createContext, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const ScrollContext = createContext();

export const useScroll = () => {
  const navigate = useNavigate();
  const context = useContext(ScrollContext);

  const scrollTo = useCallback((id) => {
    // If on a different page, navigate to the homepage first
    if (window.location.pathname !== '/') {
      navigate('/');
    }

    // Now either immediately scroll or wait for the navigation to complete
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 0);
  }, [navigate]);

  return { ...context, scrollTo };
};

export const ScrollProvider = ({ children }) => {
  const scrollTo = useCallback((id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  return <ScrollContext.Provider value={scrollTo}>{children}</ScrollContext.Provider>;
};
