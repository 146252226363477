import React from 'react';
import { Container, Typography } from '@mui/material';

const TermsOfService = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>Terms of Service</Typography>
      <Typography variant="body1" paragraph>
        Please read these terms of service ("terms", "terms of service") carefully before using [website] website (the "service") operated by Leveraged Intelligence Consultants LLC ("us", 'we", "our").
      </Typography>
      <Typography variant="body1" paragraph>
        Your access to and use of the service is conditioned on your acceptance of and compliance with these terms. These terms apply to all visitors, users and others who access or use the service.
      </Typography>
      <Typography variant="body1" paragraph>
        By accessing or using the service you agree to be bound by these terms. If you disagree with any part of the terms then you may not access the service.
      </Typography>
      <Typography variant="h5" gutterBottom>Links To Other Web Sites</Typography>
      <Typography variant="body1" paragraph>
        Our service may contain links to third-party web sites or services that are not owned or controlled by Leveraged Intelligence Consultants LLC.
      </Typography>
      <Typography variant="body1" paragraph>
        Leveraged Intelligence Consultants LLC has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Leveraged Intelligence Consultants LLC shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.
      </Typography>
      <Typography variant="body1" paragraph>
        We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.
      </Typography>
      <Typography variant="h5" gutterBottom>Termination</Typography>
      <Typography variant="body1" paragraph>
        We may terminate or suspend access to our service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the terms.
      </Typography>
      <Typography variant="body1" paragraph>
        All provisions of the terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
      </Typography>
      <Typography variant="h5" gutterBottom>Governing Law</Typography>
      <Typography variant="body1" paragraph>
        These terms shall be governed and construed in accordance with the laws of [your country], without regard to its conflict of law provisions.
      </Typography>
      <Typography variant="body1" paragraph>
        Our failure to enforce any right or provision of these terms will not be considered a waiver of those rights. If any provision of these terms is held to be invalid or unenforceable by a court, the remaining provisions of these terms will remain in effect. These terms constitute the entire agreement between us regarding our service, and supersede and replace any prior agreements we might have between us regarding the service.
      </Typography>
    </Container>
  );
};

export default TermsOfService;
