

import React, { forwardRef } from 'react';
import { Typography, Box, Grid, Card, CardMedia, useTheme } from '@mui/material';

const Automations = forwardRef(({ id }, ref) => { // Destructure id from the props
  const theme = useTheme();

  const cardMediaStyles = {
    height: 0,
    paddingTop: '56.25%', // 16:9 aspect ratio
  };

  const contentBoxStyles = {
    maxWidth: theme.breakpoints.values.md,
    margin: 'auto',
    padding: theme.spacing(5),
  };

  return (
    <Box
      ref={ref}
      id={id} // Attach the id for the scrolling functionality
      sx={{
        mt: theme.spacing(1),
        py: theme.spacing(6),
        bgcolor: theme.palette.background.paper
      }}
    >
      <Typography variant="h4" component="h2" align="center" sx={{ mb: theme.spacing(6) }}>
        Automations
      </Typography>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              image={`${process.env.PUBLIC_URL}/img4.png`}
              title="Automation Image"
              sx={cardMediaStyles}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={contentBoxStyles}>
            <Typography variant="h4" component="h2">
              Say goodbye to your boring repetitive tasks.
            </Typography>
            <Typography variant="body1">
              Allocate attention to the heavy lifting. From simple to complex tasks, if there is a way to automate, we can get it done.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={contentBoxStyles}>
            <Typography variant="h4" component="h2">
              Automate tasks across systems, breaking through data silos and program barriers.
            </Typography>
            <Typography variant="body1">
              Most systems and apps have an API to interact with, and if not, there are often ways around an API.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              image={`${process.env.PUBLIC_URL}/img5.png`}
              title="Another Automation Image"
              sx={cardMediaStyles}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
});

export default Automations;
