

import React, { forwardRef } from 'react';
import { Typography, Box, Grid, Card, CardMedia, useTheme } from '@mui/material';


const AIAssistants = forwardRef(({ id }, ref) => { // Destructure id from the props
  const theme = useTheme();

  const cardMediaStyles = {
    height: 0,
    paddingTop: '56.25%', // 16:9 aspect ratio
    borderRadius: theme.shape.borderRadius, // consistent border radius
  };

  const contentBoxStyles = {
    maxWidth: theme.breakpoints.values.md,
    margin: 'auto',
    padding: theme.spacing(5),
  };

  return (
    <Box
      ref={ref}
      id={id} // Attach the id for the scrolling functionality
      sx={{
        pt: theme.spacing(1),
        pb: theme.spacing(6),
        bgcolor: theme.palette.background.paper
      }}
    >
      <Typography variant="h4" component="h2" align="center" sx={{ mb: theme.spacing(4) }}>
        AI Assistants
      </Typography>
      <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={contentBoxStyles}>
          <Typography variant="h4" component="h2">
              Kick start your AI journey with an AI Assistant. 
            </Typography>
            <Typography variant="body1">
              With so many opportunities within the AI space, sometimes the best place to start is the one that is easiest to setup.  Your AI Assistant will be custom built and mapped out to automatically integrate in your operations and processes. Your AI Assistant will answer questions with your own custom knowledgebase and data.  We can build your knowledge base from your website or documents.  Chances is you have exactly what we need to create an effective AI Assistant.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              image={`${process.env.PUBLIC_URL}/AI-Assistant-Flow-Chart-Basic.png`}
              title="AI Assistant Flow Chart"
              sx={cardMediaStyles}
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
        <Card>
            <CardMedia
              image={`${process.env.PUBLIC_URL}/AI-Assistants.png`}
              title="AI Assistants Concept"
              sx={cardMediaStyles}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
        <Box sx={contentBoxStyles}>
        <Typography variant="h4" component="h2">
              Customer service. Sales. Employee onboarding. Customer onboarding.  And more!
            </Typography>
            <Typography variant="body1">
              Custom AI Assistants cover a range of solutions.  The AI Assistants we build and are built with purpose in mind.  We identify critical processes and intentions that take the user down a different path when triggered.  A customer service AI Assistant will be able to gather contact information, answer general questions, policies, and more.  Each AI Assistant is unique and will be built specifically for your business.
            </Typography>
          </Box>

        </Grid>
      </Grid>
    </Box>
  );
});

export default AIAssistants;
