

import React, { forwardRef } from 'react';
import { Typography, Box, Grid, Card, CardMedia, useTheme } from '@mui/material';

const WhatWeDo = forwardRef(({ id }, ref) => { // Destructure id directly from the props
  const theme = useTheme();

  // Styles for the card media (images)
  const cardMediaStyles = {
    height: 0,
    paddingTop: '56.25%', // 16:9 aspect ratio
  };

  // Styles for the content box
  const contentBoxStyles = {
    maxWidth: theme.breakpoints.values.md,
    margin: 'auto',
    padding: theme.spacing(5),
  };

  return (
    <Box
      ref={ref}
      id={id} // Attach the id for the scrolling functionality
      sx={{
        mt: theme.spacing(1),
        py: theme.spacing(6),
        bgcolor: theme.palette.background.paper
      }}
    >
      <Typography variant="h4" component="h2" align="center" sx={{ mb: theme.spacing(6) }}>
        What We Do
      </Typography>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              image={`${process.env.PUBLIC_URL}/img1.png`}
              title="Image 1"
              sx={cardMediaStyles}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={contentBoxStyles}>
            <Typography variant="h4" component="h2">
              We create solutions so companies can save time and resources.
            </Typography>
            <Typography variant="body1">
              We focus on creating automated solutions that will give companies a return on their investment, year over year. Automating mundane, repetitive tasks gives individuals the ability to make a larger impact in their work by focusing on more difficult tasks.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={contentBoxStyles}>
            <Typography variant="h4" component="h2">
              We create the perfect gateway into AI.
            </Typography>
            <Typography variant="body1">
              Most companies and people know AI is here to stay. It will impact every business. Yet most do not know where to start. We are here to say the best place to start is with an AI Assistant. Because that is what it is, a starting point. From there, the limit is your imagination. We can develop AI solutions that respond to emails, send text messages, WhatsApp messages, or even take phone calls. We can go beyond that with OCR solutions or pulling information from contracts.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              image={`${process.env.PUBLIC_URL}/img2.png`}
              title="Image 2"
              sx={cardMediaStyles}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              image={`${process.env.PUBLIC_URL}/img3.png`}
              title="Image 3"
              sx={cardMediaStyles}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={contentBoxStyles}>
            <Typography variant="h4" component="h2">
              We turn your data into stories.
            </Typography>
            <Typography variant="body1">
              We create stunning dashboards where you can see your metrics across systems in one place. We move, map, and transform data across systems with automated data pipelines. Your days of hopping through systems for reports will soon come to an end.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});

export default WhatWeDo;
