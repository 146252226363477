

// import React, { forwardRef, useState } from 'react';
// import { Box, Typography, Button, Modal, Grid, TextField, Checkbox, FormControlLabel, useTheme } from '@mui/material';
// import Confetti from 'react-confetti';
// import { styled } from '@mui/material/styles';

// const HeroTitle = styled(Typography)(({ theme }) => ({
//   fontWeight: 700, // Bold
//   fontSize: '4.5rem', // Increased font size for larger text
//   lineHeight: '1.2', // Tighter line spacing for large text
//   color: theme.palette.text.primary,
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '3rem', // Smaller font size for small screens
//   },
// }));

// const HeroSubtitle = styled(Typography)(({ theme }) => ({
//   fontSize: '1.25rem', // Adjusted for subtitle
//   color: theme.palette.text.secondary,
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '1rem', // Smaller font size for small screens
//   },
// }));



// const TextAnimation = forwardRef(({ id }, ref) => {
//   const theme = useTheme();
//   const [open, setOpen] = useState(false);
//   const [selectedServices, setSelectedServices] = useState([]);
//   const [showSuccess, setShowSuccess] = useState(false);

//   const handleCheckboxChange = (event, service) => {
//     event.stopPropagation();
//     handleServiceChange(service);
//   };

//   const handleServiceChange = (service) => {
//     setSelectedServices((prev) => prev.includes(service) ? prev.filter((s) => s !== service) : [...prev, service]);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     setOpen(false);
//     setShowSuccess(true);
//   };

//   const handleSuccessClose = () => {
//     setShowSuccess(false);
//   };

//   const modalStyle = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
//   };

//   const serviceOptions = [
//     { id: 'aiAssistants', label: 'AI Assistants' },
//     { id: 'automations', label: 'Automations' },
//     { id: 'dashboards', label: 'Dashboards' },
//     { id: 'custom', label: 'Custom' },
//   ];

//   return (

//           <Box
//       display="flex"
//       flexDirection="column"
//       justifyContent="center" // This centers the text vertically
//       alignItems="center" // This centers the text horizontally
//       minHeight="60vh" // This gives enough space for the text to be centered vertically
//       pt={theme.spacing(8)} // Padding top, using the theme object
//       pb={theme.spacing(8)} // Padding bottom, using the theme object
//     >
//       <HeroTitle variant="h3" gutterBottom>
//         Get Your <Box component="span" >Time</Box> Back.
//       </HeroTitle>
//       <HeroSubtitle variant="subtitle1" gutterBottom>
//         Automate your workflows and save hours every day.
//       </HeroSubtitle>
//       <Button variant="contained" sx={{ mt: 4 }} onClick={() => setOpen(true)}>
//         START AUTOMATING
//       </Button>
//       <Modal
//         open={open}
//         onClose={() => setOpen(false)}
//         aria-labelledby="modal-title"
//         aria-describedby="modal-description"
//       >
//         <Box sx={modalStyle} component="form" onSubmit={handleSubmit}>
//           <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
//             Contact Request
//           </Typography>
//           <Grid container spacing={2}>
//             <Grid item xs={12}><TextField fullWidth label="First Name" name="firstName" required /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Last Name" name="lastName" required /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Company" name="company" /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Email Address" type="email" name="email" required /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Phone (Optional)" name="phone" /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Comment" name="comment" multiline rows={4} required /></Grid>
//             <Grid item xs={12}>
//               <Typography variant="subtitle1" gutterBottom>
//                 Select Services:
//               </Typography>
//               {serviceOptions.map((option) => (
//                 <FormControlLabel
//                   key={option.id}
//                   control={
//                     <Checkbox
//                       checked={selectedServices.includes(option.id)}
//                       onChange={(event) => handleCheckboxChange(event, option.id)}
//                       color="primary"
//                     />
//                   }
//                   label={option.label}
//                 />
//               ))}
//             </Grid>
//             <Grid item xs={12}>
//               <Button type="submit" variant="contained" fullWidth>Submit</Button>
//             </Grid>
//           </Grid>
//         </Box>
//       </Modal>
//       {showSuccess && (
//         <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999, bgcolor: 'rgba(0, 0, 0, 0.5)' }} onClick={handleSuccessClose}>
//           <Confetti width={window.innerWidth} height={window.innerHeight} />
//           <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', zIndex: 1000, bgcolor: theme.palette.background.paper, p: 4 }}>
//             <Typography variant="h6" sx={{ mb: 2 }}>
//               Success!
//             </Typography>
//             <Typography variant="body1" sx={{ mb: 2 }}>
//               Someone will be in contact with you soon.
//             </Typography>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// });

// export default TextAnimation;

// cannot see button to submit on SE (pre)
// import React, { forwardRef, useState } from 'react';
// import { Box, Typography, Button, Modal, Grid, TextField, Checkbox, FormControlLabel, useTheme, useMediaQuery } from '@mui/material';
// import Confetti from 'react-confetti';
// import { styled } from '@mui/material/styles';

// const HeroTitle = styled(Typography)(({ theme }) => ({
//   fontWeight: 700, // Bold
//   fontSize: '4.5rem', // Increased font size for larger text
//   lineHeight: '1.2', // Tighter line spacing for large text
//   color: theme.palette.text.primary,
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '3rem', // Smaller font size for small screens
//   },
// }));

// const HeroSubtitle = styled(Typography)(({ theme }) => ({
//   fontSize: '1.25rem', // Adjusted for subtitle
//   color: theme.palette.text.secondary,
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '1rem', // Smaller font size for small screens
//   },
// }));

// const TextAnimation = forwardRef(({ id }, ref) => {
//   const theme = useTheme();
//   const [open, setOpen] = useState(false);
//   const [selectedServices, setSelectedServices] = useState([]);
//   const [showSuccess, setShowSuccess] = useState(false);
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//   const handleCheckboxChange = (event, service) => {
//     event.stopPropagation();
//     handleServiceChange(service);
//   };

//   const handleServiceChange = (service) => {
//     setSelectedServices((prev) => prev.includes(service) ? prev.filter((s) => s !== service) : [...prev, service]);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     setOpen(false);
//     setShowSuccess(true);
//   };

//   const handleSuccessClose = () => {
//     setShowSuccess(false);
//   };

//   const modalStyle = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: isMobile ? '90%' : 400, // Adjust the width for smaller screens
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
//   };

//   const serviceOptions = [
//     { id: 'aiAssistants', label: 'AI Assistants' },
//     { id: 'automations', label: 'Automations' },
//     { id: 'dashboards', label: 'Dashboards' },
//     { id: 'custom', label: 'Custom' },
//   ];

//   return (
//     <Box
//       display="flex"
//       flexDirection="column"
//       justifyContent="center" // This centers the text vertically
//       alignItems="center" // This centers the text horizontally
//       minHeight="60vh" // This gives enough space for the text to be centered vertically
//       pt={theme.spacing(8)} // Padding top, using the theme object
//       pb={theme.spacing(8)} // Padding bottom, using the theme object
//     >
//       <HeroTitle variant="h3" gutterBottom>
//         Get Your <Box component="span" >Time</Box> Back.
//       </HeroTitle>
//       <HeroSubtitle variant="subtitle1" gutterBottom>
//         Automate your workflows and save hours every day.
//       </HeroSubtitle>
//       <Button variant="contained" sx={{ mt: 4 }} onClick={() => setOpen(true)}>
//         START AUTOMATING
//       </Button>
//       <Modal
//         open={open}
//         onClose={() => setOpen(false)}
//         aria-labelledby="modal-title"
//         aria-describedby="modal-description"
//       >
//         <Box sx={modalStyle} component="form" onSubmit={handleSubmit}>
//           <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
//             Contact Request
//           </Typography>
//           <Grid container spacing={2}>
//             <Grid item xs={12}><TextField fullWidth label="First Name" name="firstName" required /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Last Name" name="lastName" required /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Company" name="company" /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Email Address" type="email" name="email" required /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Phone (Optional)" name="phone" /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Comment" name="comment" multiline rows={4} required /></Grid>
//             <Grid item xs={12}>
//               <Typography variant="subtitle1" gutterBottom>
//                 Select Services:
//               </Typography>
//               {serviceOptions.map((option) => (
//                 <FormControlLabel
//                   key={option.id}
//                   control={
//                     <Checkbox
//                       checked={selectedServices.includes(option.id)}
//                       onChange={(event) => handleCheckboxChange(event, option.id)}
//                       color="primary"
//                     />
//                   }
//                   label={option.label}
//                 />
//               ))}
//             </Grid>
//             <Grid item xs={12}>
//               <Button type="submit" variant="contained" fullWidth>Submit</Button>
//             </Grid>
//           </Grid>
//         </Box>
//       </Modal>
//       {showSuccess && (
//         <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999, bgcolor: 'rgba(0, 0, 0, 0.5)' }} onClick={handleSuccessClose}>
//           <Confetti width={window.innerWidth} height={window.innerHeight} />
//           <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', zIndex: 1000, bgcolor: theme.palette.background.paper, p: 4 }}>
//             <Typography variant="h6" sx={{ mb: 2 }}>
//               Success!
//             </Typography>
//             <Typography variant="body1" sx={{ mb: 2 }}>
//               Someone will be in contact with you soon.
//             </Typography>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// });

// export default TextAnimation;


// import React, { forwardRef, useState } from 'react';
// import { Box, Typography, Button, Dialog, Grid, TextField, Checkbox, FormControlLabel, useTheme, IconButton } from '@mui/material';
// import Confetti from 'react-confetti';
// import { styled } from '@mui/material/styles';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// const HeroTitle = styled(Typography)(({ theme }) => ({
//   fontWeight: 700,
//   fontSize: '4.5rem',
//   lineHeight: '1.2',
//   color: theme.palette.text.primary,
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '3rem',
//   },
// }));

// const HeroSubtitle = styled(Typography)(({ theme }) => ({
//   fontSize: '1.25rem',
//   color: theme.palette.text.secondary,
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '1rem',
//   },
// }));

// const TextAnimation = forwardRef(({ id }, ref) => {
//   const theme = useTheme();
//   const [open, setOpen] = useState(false);
//   const [selectedServices, setSelectedServices] = useState([]);
//   const [showSuccess, setShowSuccess] = useState(false);

//   const handleCheckboxChange = (event, service) => {
//     event.stopPropagation();
//     handleServiceChange(service);
//   };

//   const handleServiceChange = (service) => {
//     setSelectedServices((prev) => prev.includes(service) ? prev.filter((s) => s !== service) : [...prev, service]);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     setOpen(false);
//     setShowSuccess(true);
//   };

//   const handleSuccessClose = () => {
//     setShowSuccess(false);
//   };

//   const serviceOptions = [
//     { id: 'aiAssistants', label: 'AI Assistants' },
//     { id: 'automations', label: 'Automations' },
//     { id: 'dashboards', label: 'Dashboards' },
//     { id: 'custom', label: 'Custom' },
//   ];

//   return (
//     <Box
//       display="flex"
//       flexDirection="column"
//       justifyContent="center"
//       alignItems="center"
//       minHeight="60vh"
//       pt={theme.spacing(8)}
//       pb={theme.spacing(8)}
//     >
//       <HeroTitle variant="h3" gutterBottom>
//         Get Your <Box component="span">Time</Box> Back.
//       </HeroTitle>
//       <HeroSubtitle variant="subtitle1" gutterBottom>
//         Automate your workflows and save hours every day.
//       </HeroSubtitle>
//       <Button variant="contained" sx={{ mt: 4 }} onClick={() => setOpen(true)}>
//         START AUTOMATING
//       </Button>
//       <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
//         <Box p={4}>
//           <Typography variant="h6" gutterBottom>
//             Contact Request
//           </Typography>
//           <form onSubmit={handleSubmit}>
//             <Grid container spacing={2}>
//               <Grid item xs={12}><TextField fullWidth label="First Name" name="firstName" required /></Grid>
//               <Grid item xs={12}><TextField fullWidth label="Last Name" name="lastName" required /></Grid>
//               <Grid item xs={12}><TextField fullWidth label="Company" name="company" /></Grid>
//               <Grid item xs={12}><TextField fullWidth label="Email Address" type="email" name="email" required /></Grid>
//               <Grid item xs={12}><TextField fullWidth label="Phone (Optional)" name="phone" /></Grid>
//               <Grid item xs={12}><TextField fullWidth label="Comment" name="comment" multiline rows={4} required /></Grid>
//               <Grid item xs={12}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   Select Services:
//                 </Typography>
//                 {serviceOptions.map((option) => (
//                   <FormControlLabel
//                     key={option.id}
//                     control={
//                       <Checkbox
//                         checked={selectedServices.includes(option.id)}
//                         onChange={(event) => handleCheckboxChange(event, option.id)}
//                         color="primary"
//                       />
//                     }
//                     label={option.label}
//                   />
//                 ))}
//               </Grid>
//               <Grid item xs={12}>
//                 <Button type="submit" variant="contained" fullWidth>Submit</Button>
//               </Grid>
//             </Grid>
//           </form>
//         </Box>
//       </Dialog>
//       {showSuccess && (
//         <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999, bgcolor: 'rgba(0, 0, 0, 0.5)' }} onClick={handleSuccessClose}>
//           <Confetti width={window.innerWidth} height={window.innerHeight} />
//           <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', zIndex: 1000, bgcolor: theme.palette.background.paper, p: 4 }}>
//             <Typography variant="h6" sx={{ mb: 2 }}>
//               Success!
//             </Typography>
//             <Typography variant="body1" sx={{ mb: 2 }}>
//               Someone will be in contact with you soon.
//             </Typography>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// });

// export default TextAnimation;





import React, { forwardRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Box, Typography, Button, Dialog, Grid, TextField, Checkbox, FormControlLabel, useTheme, styled } from '@mui/material';
import Confetti from 'react-confetti';

const HeroTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '4.5rem',
  lineHeight: '1.2',
  color: theme.palette.text.primary,
  [theme.breakpoints.down('sm')]: {
    fontSize: '3rem',
  },
}));

const HeroSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}));

const TextAnimation = forwardRef(({ id }, ref) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCheckboxChange = (event, service) => {
    event.stopPropagation();
    handleServiceChange(service);
  };

  const handleServiceChange = (service) => {
    setSelectedServices((prev) => prev.includes(service) ? prev.filter((s) => s !== service) : [...prev, service]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('selectedServices', selectedServices.join(', '));

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      event.target,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    ).then(
      () => {
        console.log('SUCCESS!');
        setOpen(false);
        setShowSuccess(true);
      },
      (error) => {
        console.log('FAILED...', error.text);
        setErrorMessage('Failed to send your contact request. Please try again.');
      }
    ).catch((error) => {
      console.error('EmailJS error:', error);
      setErrorMessage('An unexpected error occurred. Please try again.');
    });
  };

  const handleSuccessClose = () => {
    setShowSuccess(false);
  };

  const serviceOptions = [
    { id: 'aiAssistants', label: 'AI Assistants' },
    { id: 'automations', label: 'Automations' },
    { id: 'dashboards', label: 'Dashboards' },
    { id: 'custom', label: 'Custom' },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="60vh"
      pt={theme.spacing(8)}
      pb={theme.spacing(8)}
    >
      <HeroTitle variant="h3" gutterBottom>
        Get Your <Box component="span">Time</Box> Back.
      </HeroTitle>
      <HeroSubtitle variant="subtitle1" gutterBottom>
        Automate your workflows and save hours every day.
      </HeroSubtitle>
      <Button variant="contained" sx={{ mt: 4 }} onClick={() => setOpen(true)}>
        START AUTOMATING
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <Box p={4}>
          <Typography variant="h6" gutterBottom>
            Contact Request
          </Typography>
          {errorMessage && <Typography color="error" sx={{ mb: 2 }}>{errorMessage}</Typography>}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}><TextField fullWidth label="First Name" name="firstName" required /></Grid>
              <Grid item xs={12}><TextField fullWidth label="Last Name" name="lastName" required /></Grid>
              <Grid item xs={12}><TextField fullWidth label="Company" name="company" /></Grid>
              <Grid item xs={12}><TextField fullWidth label="Email Address" type="email" name="email" required /></Grid>
              <Grid item xs={12}><TextField fullWidth label="Phone (Optional)" name="phone" /></Grid>
              <Grid item xs={12}><TextField fullWidth label="Comment" name="comment" multiline rows={4} required /></Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Select Services:
                </Typography>
                {serviceOptions.map((option) => (
                  <FormControlLabel
                    key={option.id}
                    control={
                      <Checkbox
                        checked={selectedServices.includes(option.id)}
                        onChange={(event) => handleCheckboxChange(event, option.id)}
                        color="primary"
                      />
                    }
                    label={option.label}
                  />
                ))}
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" fullWidth>Submit</Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Dialog>
      {showSuccess && (
        <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999, bgcolor: 'rgba(0, 0, 0, 0.5)' }} onClick={handleSuccessClose}>
          <Confetti width={window.innerWidth} height={window.innerHeight} />
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', zIndex: 1000, bgcolor: theme.palette.background.paper, p: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Success!
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Someone will be in contact with you soon.
            </Typography>
            <Button variant="contained" onClick={handleSuccessClose}>Close</Button>
          </Box>
        </Box>
      )}
    </Box>
  );
});

export default TextAnimation;
