

// import React from 'react';
// import { AppBar, Toolbar, Button, Typography, Box } from '@mui/material';
// import { useScroll } from '../ScrollContext'; // Adjust the path to where your ScrollContext.js is located

// const Navbar = () => {
//   const { scrollTo } = useScroll();

//   return (
//     <AppBar position="sticky" color="default" elevation={1}>
//       <Toolbar>
//         <Box sx={{ marginRight: 'auto', cursor: 'pointer' }} onClick={() => scrollTo('text-animation')}>
//           <Typography variant="h6">Leveraged Intelligence</Typography>
//         </Box>
//         <Button color="inherit" onClick={() => scrollTo('what-we-do')}>What We Do</Button>
//         <Button color="inherit" onClick={() => scrollTo('ai-assistants')}>AI Assistants</Button>
//         <Button color="inherit" onClick={() => scrollTo('automations')}>Automations</Button>
//         <Button color="inherit" onClick={() => scrollTo('dashboards')}>Dashboards</Button>
//         <Button color="inherit" onClick={() => scrollTo('pricing')}>Pricing</Button>
//         {/* Add other sections as needed */}
//         {/* Icons and other buttons would go here */}
//       </Toolbar>
//     </AppBar>
//   );
// };

// export default Navbar;
// pre mobile adjustmnets
// import React from 'react';
// import { AppBar, Toolbar, Button, Typography, Box, useMediaQuery } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
// import { useScroll } from '../ScrollContext';

// const Navbar = () => {
//   const { scrollTo } = useScroll();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//   return (
//     <AppBar position="sticky" color="default" elevation={1}>
//       <Toolbar>
//         <Box sx={{ marginRight: 'auto', cursor: 'pointer' }} onClick={() => scrollTo('text-animation')}>
//           <Typography variant="h6">Leveraged Intelligence</Typography>
//         </Box>
//         {isMobile ? (
//           <Button color="inherit" onClick={() => scrollTo('what-we-do')}>Menu</Button>
//         ) : (
//           <>
//             <Button color="inherit" onClick={() => scrollTo('what-we-do')}>What We Do</Button>
//             <Button color="inherit" onClick={() => scrollTo('ai-assistants')}>AI Assistants</Button>
//             <Button color="inherit" onClick={() => scrollTo('automations')}>Automations</Button>
//             <Button color="inherit" onClick={() => scrollTo('dashboards')}>Dashboards</Button>
//             <Button color="inherit" onClick={() => scrollTo('pricing')}>Pricing</Button>
//           </>
//         )}
//       </Toolbar>
//     </AppBar>
//   );
// };

// export default Navbar;

// import React, { useState } from 'react';
// import { AppBar, Toolbar, Button, Typography, Box, IconButton, Menu, MenuItem } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { useScroll } from '../ScrollContext';

// const Navbar = () => {
//   const { scrollTo } = useScroll();
//   const [anchorEl, setAnchorEl] = useState(null);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <AppBar position="sticky" color="default" elevation={1}>
//       <Toolbar>
//         <Box sx={{ marginRight: 'auto', cursor: 'pointer' }} onClick={() => scrollTo('text-animation')}>
//           <Typography variant="h6">Leveraged Intelligence</Typography>
//         </Box>
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <IconButton color="inherit" aria-label="menu" onClick={handleClick}>
//             <MenuIcon />
//           </IconButton>
//           <Menu
//             anchorEl={anchorEl}
//             open={Boolean(anchorEl)}
//             onClose={handleClose}
//           >
//             <MenuItem onClick={() => { scrollTo('what-we-do'); handleClose(); }}>What We Do</MenuItem>
//             <MenuItem onClick={() => { scrollTo('ai-assistants'); handleClose(); }}>AI Assistants</MenuItem>
//             <MenuItem onClick={() => { scrollTo('automations'); handleClose(); }}>Automations</MenuItem>
//             <MenuItem onClick={() => { scrollTo('dashboards'); handleClose(); }}>Dashboards</MenuItem>
//             <MenuItem onClick={() => { scrollTo('pricing'); handleClose(); }}>Pricing</MenuItem>
//           </Menu>
//         </Box>
//       </Toolbar>
//     </AppBar>
//   );
// };

// export default Navbar;
import React from 'react';
import { AppBar, Toolbar, Button, Typography, IconButton, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useScroll } from '../ScrollContext';

const Navbar = () => {
  const { scrollTo } = useScroll();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (section) => {
    scrollTo(section);
    handleMenuClose();
  };

  return (
    <AppBar position="sticky" color="default" elevation={1}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={() => scrollTo('text-animation')}>
          Leveraged Intelligence
        </Typography>
        {isMobile ? (
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <>
            <Button color="inherit" onClick={() => scrollTo('what-we-do')}>What We Do</Button>
            <Button color="inherit" onClick={() => scrollTo('ai-assistants')}>AI Assistants</Button>
            <Button color="inherit" onClick={() => scrollTo('automations')}>Automations</Button>
            <Button color="inherit" onClick={() => scrollTo('dashboards')}>Dashboards</Button>
            <Button color="inherit" onClick={() => scrollTo('pricing')}>Pricing</Button>
          </>
        )}
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleMenuItemClick('what-we-do')}>What We Do</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('ai-assistants')}>AI Assistants</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('automations')}>Automations</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('dashboards')}>Dashboards</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('pricing')}>Pricing</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

