
// export default Dashboards;
import React, { forwardRef } from 'react';
import { Typography, Box, Grid, Card, CardMedia, useTheme } from '@mui/material';

const Dashboards = forwardRef(({ id }, ref) => { // Destructure id from the props
  const theme = useTheme();

  const cardMediaStyles = {
    height: 0,
    paddingTop: '56.25%', // 16:9 aspect ratio
  };

  const contentBoxStyles = {
    maxWidth: theme.breakpoints.values.md,
    margin: 'auto',
    padding: theme.spacing(5),
  };

  return (
    <Box
      ref={ref}
      id={id} // Attach the id for the scrolling functionality
      sx={{
        mt: theme.spacing(1),
        py: theme.spacing(6),
        bgcolor: theme.palette.background.paper
      }}
    >
      <Typography variant="h4" component="h2" align="center" sx={{ mb: theme.spacing(6) }}>
        Dashboards
      </Typography>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              image={`${process.env.PUBLIC_URL}/img6.png`}
              title="Dashboard Image 1"
              sx={cardMediaStyles}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={contentBoxStyles}>
            <Typography variant="h4" component="h3">
              Stop running manual reports with automated dashboards.
            </Typography>
            <Typography variant="body1">
              We can move and transform your data, then display it in a way that tells the best story.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={contentBoxStyles}>
            <Typography variant="h4" component="h3">
              Monitor your business across systems from a single dashboard. From any device.
            </Typography>
            <Typography variant="body1">
              One system rarely handles everything within a company. In fact, we find most businesses have multiple systems. Which is why a holistic view is critical. Plus, we can put your dashboard in a place that is accessible from any device.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              image={`${process.env.PUBLIC_URL}/img7.png`}
              title="Dashboard Image 2"
              sx={cardMediaStyles}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
});

export default Dashboards;
