

import React from 'react';
import { Box, Container, Grid, Typography, IconButton, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom'; // Import RouterLink for internal navigation
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import RssFeedIcon from '@mui/icons-material/RssFeed';

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.background.default, 
        color: theme.palette.text.secondary,
        py: theme.spacing(4), // Adjusted padding
        fontSize: '0.75rem', // Smaller font size for the entire footer for consistency
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3} justifyContent="space-between">
          
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle1" color="textPrimary" gutterBottom>Quick Links</Typography>
            <Box component="ul" sx={{ listStyle: 'none', padding: 0, m: 0 }}>
              <li>
                {/* Change to RouterLink for SPA navigation */}
                <RouterLink to="/privacy-policy" style={{ color: theme.palette.primary.main, textDecoration: 'none' }}>
                  Privacy Policy
                </RouterLink>
              </li>
              {/* Continue with other links as needed */}
              <li>
                <RouterLink to="/terms" style={{ color: theme.palette.primary.main, textDecoration: 'none' }}>
                  Terms of Service
                </RouterLink>
              </li>
              <li>
                <RouterLink to="/contact-request" style={{ color: theme.palette.primary.main, textDecoration: 'none' }}>
                  Contact Us
                </RouterLink>
              </li>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" color="textPrimary" gutterBottom>Contact</Typography>
            
            <Typography variant="body2" color="textSecondary">
              Leveraged Intelligence Consultants<br />
              801 Travis St<br />
              Suite 2101<br />
              Houston, TX 77002
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>(832) 303-9019</Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" color="textPrimary" gutterBottom>Follow Us</Typography>
            <Box>
              <IconButton href="https://twitter.com/LintelligenceAI" target="_blank" aria-label="Twitter" sx={{ color: theme.palette.primary.main }}>
                <TwitterIcon />
              </IconButton>
              <IconButton href="https://www.facebook.com/leveragedintelligenceconsultants" target="_blank" aria-label="Facebook" sx={{ color: theme.palette.primary.main }}>
                <FacebookIcon />
              </IconButton>
              <IconButton href="https://www.facebook.com/leveragedintelligenceconsultants" target="_blank" aria-label="YouTube" sx={{ color: theme.palette.primary.main }}>
                <YouTubeIcon />
              </IconButton>
              <IconButton href="/blog" aria-label="Blog" sx={{ color: theme.palette.primary.main }}>
                <RssFeedIcon />
              </IconButton>
            </Box>
          </Grid>

        </Grid>
        
        <Box mt={3} textAlign="center">
          <Typography variant="caption" color="textSecondary">
            © {new Date().getFullYear()} Leveraged Intelligence Consultants LLC. All rights reserved.
          </Typography>
        </Box>

      </Container>
    </Box>
  );
};

export default Footer;
