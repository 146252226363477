
// // export default Pricing;
// //pre modal update
// import React, { forwardRef, useState } from 'react';
// import { Box, Typography, useTheme, Modal, Grid, TextField, Button, styled, Checkbox } from '@mui/material';
// import Confetti from 'react-confetti';

// const PricingBox = styled(Box)(({ theme, checked }) => ({
//   width: theme.spacing(30),
//   height: theme.spacing(30),
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   alignItems: 'center',
//   margin: theme.spacing(2),
//   borderRadius: theme.shape.borderRadius,
//   border: `1px solid ${theme.palette.primary.light}`,
//   background: checked ? theme.palette.success.main : `linear-gradient(${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
//   color: theme.palette.common.white,
//   transition: '0.3s',
//   '&:hover': {
//     background: '#80cbc4',
//     cursor: 'pointer',
//   },
// }));

// const Pricing = forwardRef(({ id }, ref) => {
//   const theme = useTheme();
//   const [open, setOpen] = useState(false);
//   const [selectedServices, setSelectedServices] = useState([]);
//   const [showSuccess, setShowSuccess] = useState(false);

//   const handleCheckboxChange = (event, service) => {
//     event.stopPropagation();
//     handleServiceChange(service);
//   };

//   const handleServiceChange = (service) => {
//     setSelectedServices((prev) => prev.includes(service) ? prev.filter((s) => s !== service) : [...prev, service]);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     console.log("Form Submitted with Services:", selectedServices);
//     setOpen(false);
//     setShowSuccess(true);
//   };

//   const handleSuccessClose = () => {
//     setShowSuccess(false);
//   };

//   const modalStyle = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
//   };

//   const serviceOptions = [
//     { id: 'aiAssistants', label: 'AI Assistants' },
//     { id: 'automations', label: 'Automations' },
//     { id: 'dashboards', label: 'Dashboards' },
//     { id: 'custom', label: 'Custom' },
//   ];

//   return (
//     <Box ref={ref} id={id} sx={{ mt: theme.spacing(1), textAlign: 'center' }}>
//       <Typography variant="h4" component="h2" sx={{ mb: theme.spacing(3) }}>
//         Pricing Plans
//       </Typography>
//       <Typography variant="subtitle1" sx={{ mb: theme.spacing(4) }}>
//         Choose the products or services that best fit your needs.
//       </Typography>
//       <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: theme.spacing(2) }}>
//         {serviceOptions.map((option) => (
//           <PricingBox
//             key={option.id}
//             checked={selectedServices.includes(option.id)}
//             onClick={() => handleServiceChange(option.id)}
//           >
//             <Checkbox
//               checked={selectedServices.includes(option.id)}
//               onChange={(event) => handleCheckboxChange(event, option.id)}
//               color="default"
//               sx={{
//                 color: theme.palette.common.white,
//                 '&.Mui-checked': {
//                   color: theme.palette.common.white,
//                 },
//                 position: 'absolute',
//                 top: 0,
//                 left: 0,
//                 margin: theme.spacing(1),
//               }}
//             />
//             <Typography variant="h6">{option.label}</Typography>
//           </PricingBox>
//         ))}
//       </Box>
//       <Button variant="contained" sx={{ mt: 4 }} onClick={() => setOpen(true)}>
//         Get Pricing
//       </Button>
//       <Modal
//         open={open}
//         onClose={() => setOpen(false)}
//         aria-labelledby="modal-title"
//         aria-describedby="modal-description"
//       >
//         <Box sx={modalStyle} component="form" onSubmit={handleSubmit}>
//           <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
//             Contact Request
//           </Typography>
//           <Grid container spacing={2}>
//             <Grid item xs={12}><TextField fullWidth label="First Name" name="firstName" required /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Last Name" name="lastName" required /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Company" name="company" /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Email Address" type="email" name="email" required /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Phone (Optional)" name="phone" /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Comment" name="comment" multiline rows={4} required /></Grid>
//             <Grid item xs={12}>
//               <Typography>
//                 Selected Services: {selectedServices.join(', ') || 'None'}
//               </Typography>
//             </Grid>
//             <Grid item xs={12}>
//               <Button type="submit" variant="contained" fullWidth>Submit</Button>
//             </Grid>
//           </Grid>
//         </Box>
//       </Modal>
//       {showSuccess && (
//         <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999, bgcolor: 'rgba(0, 0, 0, 0.5)' }} onClick={handleSuccessClose}>
//           <Confetti width={window.innerWidth} height={window.innerHeight} />
//           <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', zIndex: 1000, bgcolor: theme.palette.background.paper, p: 4 }}>
//           <Typography variant="h6" sx={{ mb: 2 }}>
//               Success!
//             </Typography>
//             <Typography variant="body1" sx={{ mb: 2 }}>
//               Someone will be in contact with you soon.
//             </Typography>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// });

// export default Pricing;

// import React, { forwardRef, useState } from 'react';
// import { Box, Typography, useTheme, Grid, TextField, Button, FormControlLabel, Checkbox, Dialog } from '@mui/material';
// import Confetti from 'react-confetti';
// import { styled } from '@mui/system';

// const Pricing = forwardRef(({ id }, ref) => {
//   const theme = useTheme();
//   const [open, setOpen] = useState(false);
//   const [selectedServices, setSelectedServices] = useState([]);
//   const [showSuccess, setShowSuccess] = useState(false);

//   // Placeholder values for isSmallScreen and modalMaxWidth
//   const isSmallScreen = true; // Replace with your logic
//   const modalMaxWidth = 'sm'; // Replace with appropriate value

//   const handleCheckboxChange = (event, service) => {
//     event.stopPropagation();
//     handleServiceChange(service);
//   };

//   const handleServiceChange = (service) => {
//     setSelectedServices((prev) => prev.includes(service) ? prev.filter((s) => s !== service) : [...prev, service]);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     setOpen(false);
//     setShowSuccess(true);
//   };

//   const handleSuccessClose = () => {
//     setShowSuccess(false);
//   };

//   return (
//     <Box
//       display="flex"
//       flexDirection="column"
//       justifyContent="center"
//       alignItems="center"
//       minHeight={isSmallScreen ? '100vh' : '60vh'}
//       pt={isSmallScreen ? theme.spacing(2) : theme.spacing(8)}
//       pb={isSmallScreen ? theme.spacing(2) : theme.spacing(8)}
//     >
//       <Typography variant={isSmallScreen ? "h4" : "h3"} gutterBottom>
//         Get Your <Box component="span">Time</Box> Back.
//       </Typography>
//       <Typography variant="subtitle1" gutterBottom>
//         Automate your workflows and save hours every day.
//       </Typography>
//       <Button variant="contained" sx={{ mt: isSmallScreen ? 2 : 4 }} onClick={() => setOpen(true)}>
//         START AUTOMATING
//       </Button>
//       <Dialog open={open} onClose={() => setOpen(false)} maxWidth={modalMaxWidth} fullWidth>
//         <Box p={isSmallScreen ? 2 : 4}>
//           <Typography variant="h6" gutterBottom>
//             Contact Request
//           </Typography>
//           <form onSubmit={handleSubmit}>
//             <Grid container spacing={2}>
//               <Grid item xs={12}><TextField fullWidth label="First Name" name="firstName" required /></Grid>
//               <Grid item xs={12}><TextField fullWidth label="Last Name" name="lastName" required /></Grid>
//               <Grid item xs={12}><TextField fullWidth label="Company" name="company" /></Grid>
//               <Grid item xs={12}><TextField fullWidth label="Email Address" type="email" name="email" required /></Grid>
//               <Grid item xs={12}><TextField fullWidth label="Phone (Optional)" name="phone" /></Grid>
//               <Grid item xs={12}><TextField fullWidth label="Comment" name="comment" multiline rows={4} required /></Grid>
//               <Grid item xs={12}>
//                 <Typography variant="subtitle1" gutterBottom>
//                   Select Services:
//                 </Typography>
//                 {/* Define serviceOptions here */}
//               </Grid>
//               <Grid item xs={12}>
//                 <Button type="submit" variant="contained" fullWidth>Submit</Button>
//               </Grid>
//             </Grid>
//           </form>
//         </Box>
//       </Dialog>
//       {showSuccess && (
//         <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999, bgcolor: 'rgba(0, 0, 0, 0.5)' }} onClick={handleSuccessClose}>
//           <Confetti width={window.innerWidth} height={window.innerHeight} />
//           <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', zIndex: 1000, bgcolor: theme.palette.background.paper, p: 4 }}>
//             <Typography variant="h6" sx={{ mb: 2 }}>
//               Success!
//             </Typography>
//             <Typography variant="body1" sx={{ mb: 2 }}>
//               Someone will be in contact with you soon.
//             </Typography>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// });

// export default Pricing;


// export default Pricing;
//pre modal update


// import React, { forwardRef, useState } from 'react';
// import { Box, Typography, useTheme, Modal, Grid, TextField, Button, styled, Checkbox } from '@mui/material';
// import Confetti from 'react-confetti';

// const PricingBox = styled(Box)(({ theme, checked }) => ({
//   width: theme.spacing(30),
//   height: theme.spacing(30),
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   alignItems: 'center',
//   margin: theme.spacing(2),
//   borderRadius: theme.shape.borderRadius,
//   border: `1px solid ${theme.palette.primary.light}`,
//   background: checked ? theme.palette.success.main : `linear-gradient(${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
//   color: theme.palette.common.white,
//   transition: '0.3s',
//   '&:hover': {
//     background: '#80cbc4',
//     cursor: 'pointer',
//   },
// }));

// const Pricing = forwardRef(({ id }, ref) => {
//   const theme = useTheme();
//   const [open, setOpen] = useState(false);
//   const [selectedServices, setSelectedServices] = useState([]);
//   const [showSuccess, setShowSuccess] = useState(false);

//   const handleCheckboxChange = (event, service) => {
//     event.stopPropagation();
//     handleServiceChange(service);
//   };

//   const handleServiceChange = (service) => {
//     setSelectedServices((prev) => prev.includes(service) ? prev.filter((s) => s !== service) : [...prev, service]);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     console.log("Form Submitted with Services:", selectedServices);
//     setOpen(false);
//     setShowSuccess(true);
//   };

//   const handleSuccessClose = () => {
//     setShowSuccess(false);
//   };

//   const modalStyle = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
//   };

//   const serviceOptions = [
//     { id: 'aiAssistants', label: 'AI Assistants' },
//     { id: 'automations', label: 'Automations' },
//     { id: 'dashboards', label: 'Dashboards' },
//     { id: 'custom', label: 'Custom' },
//   ];

//   return (
//     <Box ref={ref} id={id} sx={{ mt: theme.spacing(1), textAlign: 'center' }}>
//       <Typography variant="h4" component="h2" sx={{ mb: theme.spacing(3) }}>
//         Pricing Plans
//       </Typography>
//       <Typography variant="subtitle1" sx={{ mb: theme.spacing(4) }}>
//         Choose the products or services that best fit your needs.
//       </Typography>
//       <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: theme.spacing(2) }}>
//         {serviceOptions.map((option) => (
//           <PricingBox
//             key={option.id}
//             checked={selectedServices.includes(option.id)}
//             onClick={() => handleServiceChange(option.id)}
//           >
//             <Checkbox
//               checked={selectedServices.includes(option.id)}
//               onChange={(event) => handleCheckboxChange(event, option.id)}
//               color="default"
//               sx={{
//                 color: theme.palette.common.white,
//                 '&.Mui-checked': {
//                   color: theme.palette.common.white,
//                 },
//                 position: 'absolute',
//                 top: 0,
//                 left: 0,
//                 margin: theme.spacing(1),
//               }}
//             />
//             <Typography variant="h6">{option.label}</Typography>
//           </PricingBox>
//         ))}
//       </Box>
//       <Button variant="contained" sx={{ mt: 4 }} onClick={() => setOpen(true)}>
//         Get Pricing
//       </Button>
//       <Modal
//   open={open}
//   onClose={() => setOpen(false)}
//   aria-labelledby="modal-title"
//   aria-describedby="modal-description"
//   >
//   <Box sx={{ ...modalStyle, width: '90%' }} component="form" onSubmit={handleSubmit}> {/* Adjust modal width */}
   
//           <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
//             Contact Request
//           </Typography>
//           <Grid container spacing={2}>
//             <Grid item xs={12}><TextField fullWidth label="First Name" name="firstName" required /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Last Name" name="lastName" required /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Company" name="company" /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Email Address" type="email" name="email" required /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Phone (Optional)" name="phone" /></Grid>
//             <Grid item xs={12}><TextField fullWidth label="Comment" name="comment" multiline rows={4} required /></Grid>
//             <Grid item xs={12}>
//               <Typography>
//                 Selected Services: {selectedServices.join(', ') || 'None'}
//               </Typography>
//             </Grid>
//             <Grid item xs={12}>
//               <Button type="submit" variant="contained" fullWidth>Submit</Button>
//             </Grid>
//           </Grid>
//         </Box>
//       </Modal>
//       {showSuccess && (
//         <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999, bgcolor: 'rgba(0, 0, 0, 0.5)' }} onClick={handleSuccessClose}>
//           <Confetti width={window.innerWidth} height={window.innerHeight} />
//           <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', zIndex: 1000, bgcolor: theme.palette.background.paper, p: 4 }}>
//           <Typography variant="h6" sx={{ mb: 2 }}>
//               Success!
//             </Typography>
//             <Typography variant="body1" sx={{ mb: 2 }}>
//               Someone will be in contact with you soon.
//             </Typography>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// });

// export default Pricing;



import React, { forwardRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Box, Typography, useTheme, Modal, Grid, TextField, Button, styled, Checkbox } from '@mui/material';
import Confetti from 'react-confetti';

const PricingBox = styled(Box)(({ theme, checked }) => ({
  width: theme.spacing(30),
  height: theme.spacing(30),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.primary.light}`,
  background: checked ? theme.palette.success.main : `linear-gradient(${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
  color: theme.palette.common.white,
  transition: '0.3s',
  '&:hover': {
    background: '#80cbc4',
    cursor: 'pointer',
  },
}));

const Pricing = forwardRef(({ id }, ref) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCheckboxChange = (event, service) => {
    event.stopPropagation();
    handleServiceChange(service);
  };

  const handleServiceChange = (service) => {
    setSelectedServices((prev) => prev.includes(service) ? prev.filter((s) => s !== service) : [...prev, service]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('selectedServices', selectedServices.join(', '));

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      event.target,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    ).then(
      () => {
        console.log('SUCCESS!');
        setOpen(false);
        setShowSuccess(true);
      },
      (error) => {
        console.log('FAILED...', error.text);
        setErrorMessage('Failed to send your contact request. Please try again.');
      }
    ).catch((error) => {
      console.error('EmailJS error:', error);
      setErrorMessage('An unexpected error occurred. Please try again.');
    });
  };

  const handleSuccessClose = () => {
    setShowSuccess(false);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const serviceOptions = [
    { id: 'aiAssistants', label: 'AI Assistants' },
    { id: 'automations', label: 'Automations' },
    { id: 'dashboards', label: 'Dashboards' },
    { id: 'custom', label: 'Custom' },
  ];

  return (
    <Box ref={ref} id={id} sx={{ mt: theme.spacing(1), textAlign: 'center' }}>
      <Typography variant="h4" component="h2" sx={{ mb: theme.spacing(3) }}>
        Pricing Plans
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: theme.spacing(4) }}>
        Choose the products or services that best fit your needs.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: theme.spacing(2) }}>
        {serviceOptions.map((option) => (
          <PricingBox
            key={option.id}
            checked={selectedServices.includes(option.id)}
            onClick={() => handleServiceChange(option.id)}
          >
            <Checkbox
              checked={selectedServices.includes(option.id)}
              onChange={(event) => handleCheckboxChange(event, option.id)}
              color="default"
              sx={{
                color: theme.palette.common.white,
                '&.Mui-checked': {
                  color: theme.palette.common.white,
                },
                position: 'absolute',
                top: 0,
                left: 0,
                margin: theme.spacing(1),
              }}
            />
            <Typography variant="h6">{option.label}</Typography>
          </PricingBox>
        ))}
      </Box>
      <Button variant="contained" sx={{ mt: 4 }} onClick={() => setOpen(true)}>
        Get Pricing
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ ...modalStyle }} component="form" onSubmit={handleSubmit}>
          <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            Contact Request
          </Typography>
          {errorMessage && <Typography color="error" sx={{ mb: 2 }}>{errorMessage}</Typography>}
          <Grid container spacing={2}>
            <Grid item xs={12}><TextField fullWidth label="First Name" name="firstName" required /></Grid>
            <Grid item xs={12}><TextField fullWidth label="Last Name" name="lastName" required /></Grid>
            <Grid item xs={12}><TextField fullWidth label="Company" name="company" /></Grid>
            <Grid item xs={12}><TextField fullWidth label="Email Address" type="email" name="email" required /></Grid>
            <Grid item xs={12}><TextField fullWidth label="Phone (Optional)" name="phone" /></Grid>
            <Grid item xs={12}><TextField fullWidth label="Comment" name="comment" multiline rows={4} required /></Grid>
            <Grid item xs={12}>
              <Typography>
                Selected Services: {selectedServices.join(', ') || 'None'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" fullWidth>Submit</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {showSuccess && (
        <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999, bgcolor: 'rgba(0, 0, 0, 0.5)' }} onClick={handleSuccessClose}>
          <Confetti width={window.innerWidth} height={window.innerHeight} />
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', zIndex: 1000, bgcolor: theme.palette.background.paper, p: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Success!
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Someone will be in contact with you soon.
            </Typography>
            <Button variant="contained" onClick={handleSuccessClose}>Close</Button>
          </Box>
        </Box>
      )}
    </Box>
  );
});

export default Pricing;
