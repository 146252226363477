// src/App.js

// import React from 'react';
// import { ThemeProvider } from '@mui/material/styles';
// import theme from './theme';
// import Navbar from './components/Navbar';
// import TextAnimation from './components/TextAnimation';
// import WhatWeDo from './components/WhatWeDo';
// import AIAssistants from './components/AIAssistants';
// import Automations from './components/Automations';
// import Dashboards from './components/Dashboards'; // Import the Dashboards component


// // Add this import to your existing imports in App.js
// import Footer from './components/Footer'; // Ensure the path matches where you saved the Footer component
// import './App.css';



// function App() {
//   return (
//     <ThemeProvider theme={theme}>
//       <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
//         <Navbar />
//         <TextAnimation />
//         <WhatWeDo />
//         <AIAssistants />
//         <Automations />
//         <Dashboards />
//         {/* Other components */}
//         <Footer /> {/* Add the Footer component here */}
//       </div>
//     </ThemeProvider>
//   );
// }


// export default App;

//rever to
//src/App.js

// import React from 'react';
// import { ThemeProvider } from '@mui/material/styles';
// import theme from './theme';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Navbar from './components/Navbar';
// import Footer from './components/Footer';
// import TextAnimation from './components/TextAnimation';
// import WhatWeDo from './components/WhatWeDo';
// import AIAssistants from './components/AIAssistants';
// import Automations from './components/Automations';
// import Dashboards from './components/Dashboards';
// import PricingPage from './components/PricingPage';
// import './App.css';

// function App() {
//   return (
//     <ThemeProvider theme={theme}>
//       <Router>
//         <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
//           <Navbar />
//           {/* Define routes for your components */}
//           <Routes>
//             <Route path="/" element={<TextAnimation />} />
//             <Route path="/what-we-do" element={<WhatWeDo />} />
//             <Route path="/ai-assistants" element={<AIAssistants />} />
//             <Route path="/automations" element={<Automations />} />
//             <Route path="/dashboards" element={<Dashboards />} />
//             <Route path="/pricing" element={<PricingPage />} />
//             {/* Add more routes as needed */}
//           </Routes>
//           <Footer />
//         </div>
//       </Router>
//     </ThemeProvider>
//   );
// }

// export default App;

// import React from 'react';
// import { ThemeProvider } from '@mui/material/styles';
// import theme from './theme';
// import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
// import Navbar from './components/Navbar';
// import Footer from './components/Footer';
// import TextAnimation from './components/TextAnimation';
// import WhatWeDo from './components/WhatWeDo';
// import AIAssistants from './components/AIAssistants';
// import Automations from './components/Automations';
// import Dashboards from './components/Dashboards';
// import PricingPage from './components/PricingPage';
// import './App.css';

// // Wrapper component to handle scrolling
// const ScrollToTop = ({ children }) => {
//   const location = useLocation();
//   React.useEffect(() => {
//     if (location.state?.scrollToWhatWeDo) {
//       document.getElementById('what-we-do').scrollIntoView({ behavior: 'smooth' });
//     }
//   }, [location]);

//   return children || null;
// };

// function App() {
//   return (
//     <ThemeProvider theme={theme}>
//       <Router>
//         <ScrollToTop>
//           <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
//             <Navbar />
//             <Routes>
//               <Route path="/" element={<TextAnimation />} />
//               <Route path="/what-we-do" element={<WhatWeDo />} />
//               <Route path="/ai-assistants" element={<AIAssistants />} />
//               <Route path="/automations" element={<Automations />} />
//               <Route path="/dashboards" element={<Dashboards />} />
//               <Route path="/pricing" element={<PricingPage />} />
//             </Routes>
//             <Footer />
//           </div>
//         </ScrollToTop>
//       </Router>
//     </ThemeProvider>
//   );
// }

// export default App;

// import React from 'react';
// import { ThemeProvider } from '@mui/material/styles';
// import theme from './theme';
// import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
// import Navbar from './components/Navbar';
// import Footer from './components/Footer';
// import TextAnimation from './components/TextAnimation';
// import WhatWeDo from './components/WhatWeDo';
// import AIAssistants from './components/AIAssistants';
// import Automations from './components/Automations';
// import Dashboards from './components/Dashboards';
// import PricingPage from './components/PricingPage';
// import './App.css';

// // Utility function for smooth scrolling
// const smoothScrollTo = (elementId, duration) => {
//   const targetElement = document.getElementById(elementId);
//   if (!targetElement) return;

//   const targetPosition = targetElement.getBoundingClientRect().top;
//   const startPosition = window.pageYOffset;
//   const distance = targetPosition - startPosition;
//   let startTime = null;

//   const animation = currentTime => {
//     if (startTime === null) startTime = currentTime;
//     const timeElapsed = currentTime - startTime;
//     const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
//     window.scrollTo(0, run);
//     if (timeElapsed < duration) requestAnimationFrame(animation);
//   };

//   const easeInOutQuad = (t, b, c, d) => {
//     t /= d / 2;
//     if (t < 1) return c / 2 * t * t + b;
//     t--;
//     return -c / 2 * (t * (t - 2) - 1) + b;
//   };

//   requestAnimationFrame(animation);
// };

// // Wrapper component to handle scrolling
// const ScrollToTop = ({ children }) => {
//   const location = useLocation();

//   React.useEffect(() => {
//     if (location.state?.scrollToWhatWeDo) {
//       // Adjust the 800ms to your desired scrolling speed
//       smoothScrollTo('what-we-do', 800);
//     }
//   }, [location]);

//   return children || null;
// };

// function App() {
//   return (
//     <ThemeProvider theme={theme}>
//       <Router>
//         <ScrollToTop>
//           <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
//             <Navbar />
//             <Routes>
//               <Route path="/" element={<TextAnimation />} />
//               <Route path="/what-we-do" element={<WhatWeDo />} />
//               <Route path="/ai-assistants" element={<AIAssistants />} />
//               <Route path="/automations" element={<Automations />} />
//               <Route path="/dashboards" element={<Dashboards />} />
//               <Route path="/pricing" element={<PricingPage />} />
//               {/* Add more routes as needed */}
//             </Routes>
//             <Footer />
//           </div>
//         </ScrollToTop>
//       </Router>
//     </ThemeProvider>
//   );
// }

// export default App;

// import React from 'react';
// import Navbar from './components/Navbar';
// import WhatWeDo from './components/WhatWeDo';
// import AIAssistants from './components/AIAssistants';

// const App = () => {
//   const handleNavigate = (e) => {
//     e.preventDefault();
//     const targetId = e.currentTarget.getAttribute("href").slice(1); // Extract ID
//     const targetElement = document.getElementById(targetId);
//     if (targetElement) {
//       targetElement.scrollIntoView({ behavior: 'smooth' });
//     }
//   };

//   return (
//     <div>
//       <Navbar onNavigate={handleNavigate} />
//       <main>
//         <WhatWeDo />
//         <AIAssistants />
//       </main>
//     </div>
//   );
// };

// export default App;


// import React from 'react';
// import { ThemeProvider } from '@mui/material/styles';
// import theme from './theme'; // Make sure the path to your theme file is correct
// import Navbar from './components/Navbar';
// import WhatWeDo from './components/WhatWeDo';
// import AIAssistants from './components/AIAssistants';

// const App = () => {
//   const handleNavigate = (e) => {
//     e.preventDefault();
//     const targetId = e.currentTarget.getAttribute("href").slice(1); // Extract ID
//     const targetElement = document.getElementById(targetId);
//     if (targetElement) {
//       targetElement.scrollIntoView({ behavior: 'smooth' });
//     }
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <div>
//         <Navbar onNavigate={handleNavigate} />
//         <main>
//           <WhatWeDo />
//           <AIAssistants />
//         </main>
//       </div>
//     </ThemeProvider>
//   );
// };

// export default App;

// App.js
// import React, { useRef } from 'react';
// import { ThemeProvider } from '@mui/material/styles';
// import theme from './theme'; // Ensure this path is correct based on your project structure
// import Navbar from './components/Navbar'; // Update the path as necessary
// import WhatWeDo from './components/WhatWeDo'; // Update the path as necessary
// import AIAssistants from './components/AIAssistants'; // Update the path as necessary

// const App = () => {
//   const whatWeDoRef = useRef(null);
//   const aiAssistantsRef = useRef(null);

//   const handleNavigate = (sectionId) => {
//     let targetRef;
//     switch (sectionId) {
//       case 'what-we-do':
//         targetRef = whatWeDoRef;
//         break;
//       case 'ai-assistants':
//         targetRef = aiAssistantsRef;
//         break;
//       // Add cases for other sections as needed
//       default:
//         return;
//     }

//     if (targetRef && targetRef.current) {
//       targetRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Navbar onNavigate={handleNavigate} />
//       <div>
//         <WhatWeDo ref={whatWeDoRef} />
//         <AIAssistants ref={aiAssistantsRef} />
//         {/* Include other sections/components as needed */}
//       </div>
//     </ThemeProvider>
//   );
// };

// export default App;
// working without automations
// import React, { useRef } from 'react';
// import { ThemeProvider } from '@mui/material/styles';
// import theme from './theme'; // Ensure this path is correct
// import Navbar from './components/Navbar';
// import WhatWeDo from './components/WhatWeDo';
// import AIAssistants from './components/AIAssistants';
// import TextAnimation from './components/TextAnimation'; // Import the TextAnimation component

// const App = () => {
//   const textAnimationRef = useRef(null);
//   const whatWeDoRef = useRef(null);
//   const aiAssistantsRef = useRef(null);

//   const handleNavigate = (sectionId) => {
//     let targetRef;
//     switch (sectionId) {
//       case 'text-animation':
//         targetRef = textAnimationRef;
//         break;
//       case 'what-we-do':
//         targetRef = whatWeDoRef;
//         break;
//       case 'ai-assistants':
//         targetRef = aiAssistantsRef;
//         break;
//       // Handle other sections similarly
//       default:
//         return;
//     }

//     if (targetRef && targetRef.current) {
//       targetRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Navbar onNavigate={handleNavigate} onLogoClick={() => handleNavigate('text-animation')} />
//       <TextAnimation ref={textAnimationRef} />
//       <WhatWeDo ref={whatWeDoRef} />
//       <AIAssistants ref={aiAssistantsRef} />
//       {/* Include other sections/components as needed */}
//     </ThemeProvider>
//   );
// };

// export default App;

//working with automations pre dashboards
// import React, { useRef } from 'react';
// import { ThemeProvider } from '@mui/material/styles';
// import theme from './theme';
// import Navbar from './components/Navbar';
// import WhatWeDo from './components/WhatWeDo';
// import AIAssistants from './components/AIAssistants';
// import TextAnimation from './components/TextAnimation';
// import Automations from './components/Automations'; // Import the Automations component

// const App = () => {
//   const textAnimationRef = useRef(null);
//   const whatWeDoRef = useRef(null);
//   const aiAssistantsRef = useRef(null);
//   const automationsRef = useRef(null); // Create the ref for the Automations component

//   const handleNavigate = (sectionId) => {
//     let targetRef;
//     switch (sectionId) {
//       case 'text-animation':
//         targetRef = textAnimationRef;
//         break;
//       case 'what-we-do':
//         targetRef = whatWeDoRef;
//         break;
//       case 'ai-assistants':
//         targetRef = aiAssistantsRef;
//         break;
//       case 'automations': // Case for the Automations section
//         targetRef = automationsRef;
//         break;
//       // Add cases for other sections as needed
//       default:
//         return;
//     }

//     if (targetRef && targetRef.current) {
//       targetRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Navbar onNavigate={handleNavigate} onLogoClick={() => handleNavigate('text-animation')} />
//       <TextAnimation ref={textAnimationRef} />
//       <WhatWeDo ref={whatWeDoRef} />
//       <AIAssistants ref={aiAssistantsRef} />
//       <Automations ref={automationsRef} /> {/* Include the Automations component with its ref */}
//       {/* You can add more sections/components here as needed */}
//     </ThemeProvider>
//   );
// };

// export default App;

// working through dasbhaord pre pricing section
// import React, { useRef } from 'react';
// import { ThemeProvider } from '@mui/material/styles';
// import theme from './theme';
// import Navbar from './components/Navbar';
// import WhatWeDo from './components/WhatWeDo';
// import AIAssistants from './components/AIAssistants';
// import TextAnimation from './components/TextAnimation';
// import Automations from './components/Automations';
// import Dashboards from './components/Dashboards'; // Import the Dashboards component

// const App = () => {
//   const textAnimationRef = useRef(null);
//   const whatWeDoRef = useRef(null);
//   const aiAssistantsRef = useRef(null);
//   const automationsRef = useRef(null);
//   const dashboardsRef = useRef(null); // Create the ref for the Dashboards component

//   const handleNavigate = (sectionId) => {
//     let targetRef;
//     switch (sectionId) {
//       case 'text-animation':
//         targetRef = textAnimationRef;
//         break;
//       case 'what-we-do':
//         targetRef = whatWeDoRef;
//         break;
//       case 'ai-assistants':
//         targetRef = aiAssistantsRef;
//         break;
//       case 'automations':
//         targetRef = automationsRef;
//         break;
//       case 'dashboards': // Case for the Dashboards section
//         targetRef = dashboardsRef;
//         break;
//       // Handle other sections similarly
//       default:
//         // If sectionId doesn't match, return early
//         return;
//     }

//     if (targetRef && targetRef.current) {
//       // Use the scrollIntoView method to scroll to the ref
//       targetRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Navbar onNavigate={handleNavigate} onLogoClick={() => handleNavigate('text-animation')} />
//       <TextAnimation ref={textAnimationRef} />
//       <WhatWeDo ref={whatWeDoRef} />
//       <AIAssistants ref={aiAssistantsRef} />
//       <Automations ref={automationsRef} />
//       <Dashboards ref={dashboardsRef} /> {/* Include the Dashboards component with its ref */}
//       {/* You can add more sections/components here as needed */}
//     </ThemeProvider>
//   );
// };

// export default App;
// THROUGH pricing, pre footer
// import React, { useRef } from 'react';
// import { ThemeProvider } from '@mui/material/styles';
// import theme from './theme';
// import Navbar from './components/Navbar';
// import WhatWeDo from './components/WhatWeDo';
// import AIAssistants from './components/AIAssistants';
// import TextAnimation from './components/TextAnimation';
// import Automations from './components/Automations';
// import Dashboards from './components/Dashboards';
// import Pricing from './components/Pricing'; // Import the Pricing component

// const App = () => {
//   const textAnimationRef = useRef(null);
//   const whatWeDoRef = useRef(null);
//   const aiAssistantsRef = useRef(null);
//   const automationsRef = useRef(null);
//   const dashboardsRef = useRef(null);
//   const pricingRef = useRef(null); // Create the ref for the Pricing component

//   const handleNavigate = (sectionId) => {
//     let targetRef;
//     switch (sectionId) {
//       case 'text-animation':
//         targetRef = textAnimationRef;
//         break;
//       case 'what-we-do':
//         targetRef = whatWeDoRef;
//         break;
//       case 'ai-assistants':
//         targetRef = aiAssistantsRef;
//         break;
//       case 'automations':
//         targetRef = automationsRef;
//         break;
//       case 'dashboards':
//         targetRef = dashboardsRef;
//         break;
//       case 'pricing': // Case for the Pricing section
//         targetRef = pricingRef;
//         break;
//       default:
//         return;
//     }

//     if (targetRef && targetRef.current) {
//       targetRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Navbar onNavigate={handleNavigate} onLogoClick={() => handleNavigate('text-animation')} />
//       <TextAnimation ref={textAnimationRef} />
//       <WhatWeDo ref={whatWeDoRef} />
//       <AIAssistants ref={aiAssistantsRef} />
//       <Automations ref={automationsRef} />
//       <Dashboards ref={dashboardsRef} />
//       <Pricing ref={pricingRef} /> {/* Include the Dashboards component with its ref */}
//       {/* You can add more sections/components here as needed */}
//     </ThemeProvider>
//   );
// };

// export default App;
//working pre  footer privacy policy
// import React, { useRef } from 'react';
// import { ThemeProvider } from '@mui/material/styles';
// import theme from './theme';
// import Navbar from './components/Navbar';
// import WhatWeDo from './components/WhatWeDo';
// import AIAssistants from './components/AIAssistants';
// import TextAnimation from './components/TextAnimation';
// import Automations from './components/Automations';
// import Dashboards from './components/Dashboards';
// import Pricing from './components/Pricing'; // Import the Pricing component
// import Footer from './components/Footer'; // Import the Footer component

// const App = () => {
//   const textAnimationRef = useRef(null);
//   const whatWeDoRef = useRef(null);
//   const aiAssistantsRef = useRef(null);
//   const automationsRef = useRef(null);
//   const dashboardsRef = useRef(null);
//   const pricingRef = useRef(null); // Create the ref for the Pricing component

//   const handleNavigate = (sectionId) => {
//     let targetRef;
//     switch (sectionId) {
//       case 'text-animation':
//         targetRef = textAnimationRef;
//         break;
//       case 'what-we-do':
//         targetRef = whatWeDoRef;
//         break;
//       case 'ai-assistants':
//         targetRef = aiAssistantsRef;
//         break;
//       case 'automations':
//         targetRef = automationsRef;
//         break;
//       case 'dashboards':
//         targetRef = dashboardsRef;
//         break;
//       case 'pricing': // Case for the Pricing section
//         targetRef = pricingRef;
//         break;
//       default:
//         return;
//     }

//     if (targetRef && targetRef.current) {
//       targetRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Navbar onNavigate={handleNavigate} onLogoClick={() => handleNavigate('text-animation')} />
//       <TextAnimation ref={textAnimationRef} />
//       <WhatWeDo ref={whatWeDoRef} />
//       <AIAssistants ref={aiAssistantsRef} />
//       <Automations ref={automationsRef} />
//       <Dashboards ref={dashboardsRef} />
//       <Pricing ref={pricingRef} />
//       <Footer /> {/* Include the Footer component */}
//     </ThemeProvider>
//   );
// };

// export default App;

// App.js

// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { ThemeProvider } from '@mui/material/styles';
// import theme from './theme';
// import { ScrollProvider } from './ScrollContext'; // Ensure this is the correct path to your ScrollContext.js
// import Navbar from './components/Navbar';
// import Footer from './components/Footer';
// import TextAnimation from './components/TextAnimation';
// import WhatWeDo from './components/WhatWeDo';
// import AIAssistants from './components/AIAssistants';
// import Automations from './components/Automations';
// import Dashboards from './components/Dashboards';
// import Pricing from './components/Pricing';
// import PrivacyPolicy from './components/PrivacyPolicy'; // Ensure this is the correct path to your PrivacyPolicy.js
// import TermsOfService from './components/TermsOfService';
// import ContactRequest from './components/ContactRequest';

// const App = () => {
//   return (
//     <ScrollProvider>
//       <ThemeProvider theme={theme}>
//         <Router>
//           <Navbar />
//           <Routes>
//             <Route path="/" element={
//               <>
//                 <TextAnimation id="text-animation" />
//                 <WhatWeDo id="what-we-do" />
//                 <AIAssistants id="ai-assistants" />
//                 <Automations id="automations" />
//                 <Dashboards id="dashboards" />
//                 <Pricing id="pricing" />
//               </>
//             } />
//             <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//             <Route path="/terms" element={<TermsOfService />} />
//             <Route path="/contact-request" element={<ContactRequest />} />
//             {/* Add additional routes as needed */}
//           </Routes>
//           <Footer />
//         </Router>
//       </ThemeProvider>
//     </ScrollProvider>
//   );
// };

// export default App;

// working pre ga4 script
// import React, { useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { ThemeProvider } from '@mui/material/styles';
// import emailjs from '@emailjs/browser';
// import theme from './theme';
// import { ScrollProvider } from './ScrollContext';
// import Navbar from './components/Navbar';
// import Footer from './components/Footer';
// import TextAnimation from './components/TextAnimation';
// import WhatWeDo from './components/WhatWeDo';
// import AIAssistants from './components/AIAssistants';
// import Automations from './components/Automations';
// import Dashboards from './components/Dashboards';
// import Pricing from './components/Pricing';
// import PrivacyPolicy from './components/PrivacyPolicy';
// import TermsOfService from './components/TermsOfService';
// import ContactRequest from './components/ContactRequest';


// // Replace "your_public_key" with your actual EmailJS public key
// const EMAILJS_PUBLIC_KEY = "C1NWj8gyz7HdQX5HA";

// const App = () => {
//   useEffect(() => {
//     emailjs.init(EMAILJS_PUBLIC_KEY);
//   }, []);

//   return (
//     <ScrollProvider>
//       <ThemeProvider theme={theme}>
//         <Router>
//           <Navbar />
//           <Routes>
//             <Route path="/" element={
//               <>
//                 <TextAnimation id="text-animation" />
//                 <WhatWeDo id="what-we-do" />
//                 <AIAssistants id="ai-assistants" />
//                 <Automations id="automations" />
//                 <Dashboards id="dashboards" />
//                 <Pricing id="pricing" />
//               </>
//             } />
//             <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//             <Route path="/terms" element={<TermsOfService />} />
//             <Route path="/contact-request" element={<ContactRequest />} />
//             {/* Add additional routes as needed */}
//           </Routes>
//           <Footer />
//         </Router>
//       </ThemeProvider>
//     </ScrollProvider>
//   );
// };

// export default App;

// import React, { useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
// import { ThemeProvider } from '@mui/material/styles';
// import emailjs from '@emailjs/browser';
// import theme from './theme';
// import { ScrollProvider } from './ScrollContext';
// import Navbar from './components/Navbar';
// import Footer from './components/Footer';
// import TextAnimation from './components/TextAnimation';
// import WhatWeDo from './components/WhatWeDo';
// import AIAssistants from './components/AIAssistants';
// import Automations from './components/Automations';
// import Dashboards from './components/Dashboards';
// import Pricing from './components/Pricing';
// import PrivacyPolicy from './components/PrivacyPolicy';
// import TermsOfService from './components/TermsOfService';
// import ContactRequest from './components/ContactRequest';

// // Replace "your_public_key" with your actual EmailJS public key
// const EMAILJS_PUBLIC_KEY = "C1NWj8gyz7HdQX5HA";

// // Your GA4 Measurement ID
// const GA_MEASUREMENT_ID = "G-DXENZXNRR0"; // Your actual Measurement ID

// const App = () => {
//   useEffect(() => {
//     // Initialize EmailJS
//     emailjs.init(EMAILJS_PUBLIC_KEY);
  
//     // Explicitly reference `window.dataLayer` to avoid ESLint errors
//     window.dataLayer = window.dataLayer || [];
    
//     // Declare `gtag` as a function that uses `window.dataLayer.push`
//     function gtag() {
//       window.dataLayer.push(arguments);
//     }
    
//     gtag('js', new Date());
//     gtag('config', GA_MEASUREMENT_ID);
  
//     // Dynamically load the gtag script
//     const script = document.createElement('script');
//     script.async = true;
//     script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
//     document.head.appendChild(script);
//   }, []);
  


//   return (
//     <ScrollProvider>
//       <ThemeProvider theme={theme}>
//         <Router>
//           <Navbar />
//           <Routes>
//             <Route path="/" element={
//               <>
//                 <TextAnimation id="text-animation" />
//                 <WhatWeDo id="what-we-do" />
//                 <AIAssistants id="ai-assistants" />
//                 <Automations id="automations" />
//                 <Dashboards id="dashboards" />
//                 <Pricing id="pricing" />
//               </>
//             } />
//             <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//             <Route path="/terms" element={<TermsOfService />} />
//             <Route path="/contact-request" element={<ContactRequest />} />
//             {/* Additional routes here */}
//           </Routes>
//           <Footer />
//         </Router>
//       </ThemeProvider>
//     </ScrollProvider>
//   );
// };

// export default App;
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import emailjs from '@emailjs/browser';
import theme from './theme';
import { ScrollProvider } from './ScrollContext';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import TextAnimation from './components/TextAnimation';
import WhatWeDo from './components/WhatWeDo';
import AIAssistants from './components/AIAssistants';
import Automations from './components/Automations';
import Dashboards from './components/Dashboards';
import Pricing from './components/Pricing';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import ContactRequest from './components/ContactRequest';

// Replace "your_public_key" with your actual EmailJS public key
const EMAILJS_PUBLIC_KEY = "C1NWj8gyz7HdQX5HA";

// Your GA4 Measurement ID
const GA_MEASUREMENT_ID = "G-DXENZXNRR0"; // Your actual Measurement ID

const App = () => {
  useEffect(() => {
    // Initialize EmailJS
    emailjs.init(EMAILJS_PUBLIC_KEY);
  
    // Explicitly reference `window.dataLayer` to avoid ESLint errors
    window.dataLayer = window.dataLayer || [];
    
    // Declare `gtag` as a function that uses `window.dataLayer.push`
    function gtag() {
      window.dataLayer.push(arguments);
    }
    
    gtag('js', new Date());
    gtag('config', GA_MEASUREMENT_ID);
  
    // Dynamically load the gtag script
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
    document.head.appendChild(script);
  }, []);
  
  // Update the title
  useEffect(() => {
    document.title = "Leveraged Intelligence"; // Replace "Your New Title Here" with your desired title
  }, []);

  return (
    <ScrollProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={
              <>
                <TextAnimation id="text-animation" />
                <WhatWeDo id="what-we-do" />
                <AIAssistants id="ai-assistants" />
                <Automations id="automations" />
                <Dashboards id="dashboards" />
                <Pricing id="pricing" />
              </>
            } />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/contact-request" element={<ContactRequest />} />
            {/* Additional routes here */}
          </Routes>
          <Footer />
        </Router>
      </ThemeProvider>
    </ScrollProvider>
  );
};

export default App;
